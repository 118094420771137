import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { BasicHero } from "@shared";
import { PageWidth, Section } from "@util/standard";
import Faq from "@components/faq";
import { StyledAccordion } from "@styles/accordionStyles";

interface Props extends PageProps {
  data: Query;
}

export default function FaqsLandingPage({
  data: {
    sanityFaqsLanding,
    sanityHeader,
    allSanityFaq: { nodes },
  },
}: Props) {
  if (sanityFaqsLanding == null)
    return <ErrorMsg data={sanityFaqsLanding} msg="Error fetching data for page" />;

  const { _type, pageColour, title, seo, blockContent, ctas, blocks } = sanityFaqsLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug="faqs" />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={blockContent} ctas={ctas} />

      {/* TODO: Move to own component and handle variation logic */}
      <Section aria-label="Frequently asked questions" marginOverride="75px 0px">
        <PageWidth>
          <StyledAccordion allowZeroExpanded>
            {nodes.map(node => (
              <Faq key={node._id} data={node} />
            ))}
          </StyledAccordion>
        </PageWidth>
      </Section>
      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query faqsLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityFaqsLanding {
      seo {
        ...sanitySeo
      }
      _type
      title
      blockContent {
        _rawBlockContent
      }
      ctas {
        ...sanityLink
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
    }
    allSanityFaq(sort: { order: ASC, fields: order }) {
      nodes {
        ...sanityFaq
      }
    }
  }
`;
